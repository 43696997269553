import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Links = styled.div`
  display: flex;
  margin-top: 28px;

  & > *:first-child {
    margin-right: 16px;
  }

  img {
    height: 40px;
    width: auto;
    flex-shrink: 0;
  }
`;

export const Subtitle = styled.h3`
  margin: 32px 0 64px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    max-width: 400px;
    align-items: flex-start;
    padding-top: 70px;

    text-align: left;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    max-width: 600px;
  }
`;

export const Image = styled.img`
  display: none;
  width: 274px;
  height: auto;

  object-fit: contain;

  @media (min-width: ${MEDIA.TABLET}) {
    display: block;
    margin-left: 50px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    margin-left: 100px;
  }
`;
