import * as React from 'react';

import { Communication } from 'theme/icons/420';

import FeatureItemSection from 'components/FeatureItemSection';

const CommunicationSection = () => (
  <FeatureItemSection
    title='Внешние и внутренние коммуникации'
    description='Подключайте внешние контакты для работы с клиентами и партнерами, расширяйте диапазон общения, делайте взаимодействие коллег более динамичным – эффективность работы сотрудников повысится'
    icon={Communication}
    reverse
  />
);

export default CommunicationSection;
