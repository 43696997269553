import * as React from 'react';

import { SectionWrapper } from 'components/Layout';
import GetAccessButton from 'components/GetAccessButton';

import googlePlay from 'images/googlePlay.png';
import appStore from 'images/appStore.png';
import communicationPhone from 'images/communicationPhone.png';

import { Wrapper, Links, Subtitle, Content, Image } from './styled';

const Main = () => (
  <SectionWrapper>
    <Wrapper>
      <Content>
        <h1>Single Desk</h1>

        <Subtitle>
          Сформируйте единое цифровое пространство вместо разобщенных сервисов
        </Subtitle>

        <GetAccessButton />

        <Links>
          <a href='#'>
            <img src={googlePlay} alt='google play' />
          </a>

          <a href='#'>
            <img src={appStore} alt='google play' />
          </a>
        </Links>
      </Content>

      <Image src={communicationPhone} alt='main-section-phone' />
    </Wrapper>
  </SectionWrapper>
);

export default Main;
