import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';
import { LogoOutlined } from 'theme/icons/logo';

export const Wrapper = styled.main`
  position: relative;
`;

export const PseudoLogo = styled(LogoOutlined)`
  position: absolute;
  z-index: -1;
  top: -200px;
  left: 50%;

  width: 320px;
  height: 320px;
  flex-shrink: 0;

  transform: translateX(-50%);

  @media (min-width: ${MEDIA.TABLET}) {
    top: 30px;

    width: 780px;
    height: 780px;

    transform: translateX(-120%);
  }
`;
