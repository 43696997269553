import * as React from 'react';

import { Helmet } from 'react-helmet';

import {
  Main,
  Requirements,
  Safety,
  Business,
  Comfort,
  Teamwork,
  Communication,
  Groups,
  Features,
  GetAccess,
  MouseSection,
} from './screens';
import { Wrapper, PseudoLogo } from './styled';

const Home = () => (
  <React.Fragment>
    <Helmet>
      <title>SingleDesk</title>

      <meta name='description' content='Description' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
    </Helmet>

    <Wrapper>
      <Main />

      <MouseSection />

      <Features />

      <Requirements />

      <Safety />

      <Business />

      <Comfort />

      <Teamwork />

      <Communication />

      <Groups />

      <GetAccess />

      <PseudoLogo />
    </Wrapper>
  </React.Fragment>
);

export default Home;
