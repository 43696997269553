import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import { Wrapper, Title, Items, Item, ItemTitle, IconWrapper } from './styled';

interface ItemProps {
  icon: React.JSXElementConstructor<React.SVGProps<SVGElement>>;
  title: string;
}

interface ColumnItemsSectionProps {
  title: string;
  items: ItemProps[];
}

const ColumnItemsSection: React.FC<ColumnItemsSectionProps> = ({
  title,
  items,
}) => (
  <SectionWrapper>
    <Wrapper>
      <Title>{title}</Title>

      <Items>
        {items.map(({ icon: Icon, title: itemTitle }) => (
          <Item key={itemTitle}>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <ItemTitle>{itemTitle}</ItemTitle>
          </Item>
        ))}
      </Items>
    </Wrapper>
  </SectionWrapper>
);

export default ColumnItemsSection;
