import * as React from 'react';

import { Interface, OpenId, Server } from 'theme/icons/200';

import ColumnItemsSection from 'components/ColumnItemsSection';

const Business = () => (
  <ColumnItemsSection
    title='Нацелена на бизнес'
    items={[
      {
        title:
          'Красивый и удобный интерфейс, к которому быстро привыкнут сотрудники',
        icon: Interface,
      },
      {
        title:
          'Аутентификация при помощи единой корпоративной учетной записи – Web Single Sign-On на основе стандарта OpenID Connect',
        icon: OpenId,
      },
      {
        title:
          'Рабочие Поддержка SaaS и On-Premise – возможность установки как на удаленном сервере, так и на локальном сервере в собственном дата-центрепространства пользователей изолированы',
        icon: Server,
      },
    ]}
  />
);

export default Business;
