import { styled } from '@linaria/react';

import { TEXT, COLOR, TRANSITION } from 'theme/vars';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  overflow: auto;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
  }
`;

export const Tab = styled.button`
  padding-bottom: 4px;

  ${TEXT.MEDIUM_REGULAR};
  color: var(${COLOR.N_SECONDARY_300});
  cursor: pointer;
  transition: var(${TRANSITION.DEFAULT});
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    color: var(${COLOR.N_PRIMARY});
  }

  &:not(:last-of-type) {
    margin-right: 40px;
  }

  &[data-active] {
    color: var(${COLOR.N_PRIMARY});
    border-bottom: 4px solid var(${COLOR.N_PRIMARY});
  }
`;
