import * as React from 'react';

import { Teamwork } from 'theme/icons/420';

import FeatureItemSection from 'components/FeatureItemSection';

const TeamworkSection = () => (
  <FeatureItemSection
    title='Удобная командная работа'
    description='С помощью Single Desk объединяйте сотрудников,
создавайте команды и укрепляйте сплоченность,
управляйте коммуникациями,
выстраивайте потоки информации в коллективе'
    icon={Teamwork}
  />
);

export default TeamworkSection;
