import * as React from 'react';

import { SectionWrapper } from 'components/Layout';
import GetAccessButton from 'components/GetAccessButton';

import { Wrapper, Description } from './styled';

const GetAccess = () => (
  <SectionWrapper>
    <Wrapper>
      <GetAccessButton />

      <Description>
        Если вы ищете инструмент повышения производительности для своих офисных,
        удаленных или распределенных сотрудников, попробуйте нашу платформу! Или
        напишите нам по адресу{' '}
        <a href='mailto:info@singledesk.ru'>info@singledesk.ru</a>
      </Description>
    </Wrapper>
  </SectionWrapper>
);

export default GetAccess;
