import * as React from 'react';

import { Edit, Lightning } from 'theme/icons/74';

import { SectionWrapper } from 'components/Layout';

import communicationPhone from 'images/communicationPhone.png';

import { Tabs } from './components';
import {
  Wrapper,
  Items,
  Item,
  IconWrapper,
  ItemTitle,
  ItemInfo,
  Image,
  Content,
} from './styled';

const TABS = [
  { id: 'communication', name: 'Коммуникации' },
  { id: 'employees', name: 'Сотрудники' },
  { id: 'calendar', name: 'Календарь' },
  { id: 'tasks', name: 'Задачи' },
  { id: 'files', name: 'Файлы и документооборот' },
];

interface SectionItem {
  title: string;
  description: string;
  icon: React.JSXElementConstructor<React.SVGProps<SVGElement>>;
}

interface Section {
  title: string;
  image: string;
  items: SectionItem[];
}

const SECTIONS_BY_TAB: Record<string, Section> = {
  communication: {
    title: 'Взаимодействие, каким должно быть',
    image: communicationPhone,
    items: [
      {
        title: 'Держите высокий темп',
        description:
          'Обменивайтесь текстовыми и аудио сообщениями, отправляйте и просматривайте файлы, оперативно отвечайте и используйте эмодзи для реакции',
        icon: Lightning,
      },
      {
        title: 'Работайте с контентом',
        description:
          'Делитесь документами, просматривайте таблицы, фото и видео, прослушивайте аудио прямо в приложении',
        icon: Edit,
      },
    ],
  },
};

const Features = () => {
  const [activeTabId, setActiveTabId] = React.useState('communication');

  const section = SECTIONS_BY_TAB[activeTabId];

  return (
    <SectionWrapper>
      <Tabs tabs={TABS} activeTabId={activeTabId} onChange={setActiveTabId} />

      {section && (
        <Wrapper>
          <Content>
            <h2>{section.title}</h2>

            <Items>
              {section.items.map(
                ({ title: itemTitle, icon: Icon, description }) => (
                  <Item key={itemTitle}>
                    <IconWrapper>
                      <Icon />
                    </IconWrapper>

                    <ItemInfo>
                      <ItemTitle>{itemTitle}</ItemTitle>

                      <p>{description}</p>
                    </ItemInfo>
                  </Item>
                ),
              )}
            </Items>
          </Content>

          <Image src={section.image} alt={`${section.title} image`} />
        </Wrapper>
      )}
    </SectionWrapper>
  );
};

export default Features;
