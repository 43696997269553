import * as React from 'react';

import { Wrapper, Tab } from './styled';

interface TabProps {
  id: string;
  name: string;
}

interface TabsProps {
  tabs: TabProps[];
  activeTabId: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTabId, onChange }) => (
  <Wrapper>
    {tabs.map(({ id, name }) => (
      <Tab
        key={id}
        data-active={activeTabId === id || undefined}
        onClick={() => onChange(id)}
      >
        {name}
      </Tab>
    ))}
  </Wrapper>
);

export default Tabs;
