import { styled } from '@linaria/react';

import { TEXT, MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 50px;

  @media (min-width: ${MEDIA.TABLET}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Title = styled.h2`
  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    margin-top: 40px;

    text-align: left;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: ${MEDIA.TABLET}) {
    width: 120px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    width: 200px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ItemTitle = styled.p`
  ${TEXT.BOLD};

  @media (min-width: ${MEDIA.DESKTOP}) {
    width: 200px;
  }
`;
