import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import {
  Wrapper,
  Items,
  Item,
  ItemTitle,
  IconWrapper,
  Title,
  Description,
} from './styled';

interface ItemProps {
  icon: React.JSXElementConstructor<React.SVGProps<SVGElement>>;
  title: string;
  description: string;
}

interface ColumnItemsSectionProps {
  title: string;
  items: ItemProps[];
}

const RowItemsSection: React.FC<ColumnItemsSectionProps> = ({
  title,
  items,
}) => (
  <SectionWrapper>
    <Wrapper>
      <Title>{title}</Title>

      <Items>
        {items.map(({ icon: Icon, title: itemTitle, description }) => (
          <Item key={itemTitle}>
            <IconWrapper>
              <Icon />
            </IconWrapper>

            <ItemTitle>{itemTitle}</ItemTitle>

            <Description>{description}</Description>
          </Item>
        ))}
      </Items>
    </Wrapper>
  </SectionWrapper>
);

export default RowItemsSection;
