import { styled } from '@linaria/react';

import { MEDIA, TEXT } from 'theme/vars';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 560px;
  margin-top: 70px;

  @media (min-width: ${MEDIA.TABLET}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;

  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    max-width: calc(100% - 274px);
    align-items: flex-start;

    text-align: left;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    max-width: 600px;
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 28px;
  margin-top: 32px;

  text-align: left;

  @media (min-width: ${MEDIA.TABLET}) {
    margin-top: 80px;
    gap: 50px;
  }

  @media (min-width: ${MEDIA.TABLET}) {
    margin-top: 56px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;

  @media (min-width: ${MEDIA.TABLET}) {
    width: 74px;
    margin-right: 32px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled.p`
  margin-bottom: 8px;

  ${TEXT.MEDIUM_REGULAR};
`;

export const Image = styled.img`
  position: relative;

  width: 274px;
  height: auto;
  margin: 30px auto 0;

  object-fit: contain;

  @media (min-width: ${MEDIA.TABLET}) {
    right: -100px;

    margin: 0;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    right: auto;

    margin: 0 0 0 100px;
  }
`;
