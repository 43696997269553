import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 50px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 80px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    gap: 90px;
  }
`;

export const Title = styled.h2`
  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    text-align: left;
  }
`;

export const ItemTitle = styled.h4`
  margin: 10px 0 8px;
  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    text-align: left;
  }
`;

export const Description = styled.p`
  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    text-align: left;
  }
`;

export const Items = styled.div`
  width: 100%;
  display: grid;
  gap: 70px;

  @media (min-width: ${MEDIA.TABLET}) {
    grid-template-columns: 1fr 1fr;
    gap: 30px 50px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${MEDIA.TABLET}) {
    align-items: flex-start;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${MEDIA.TABLET}) {
    justify-content: flex-start;
  }

  svg {
    flex-shrink: 0;
  }
`;
