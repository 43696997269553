import * as React from 'react';

import { Groups } from 'theme/icons/420';

import FeatureItemSection from 'components/FeatureItemSection';

const GroupsSection = () => (
  <FeatureItemSection
    title='Управление рабочими группами'
    description='Всего за несколько кликов создавайте сообщества и группы, распространяйте ключевую информацию, ведите рабочие планы и предлагайте мероприятия, повышайте социализацию сотрудников'
    icon={Groups}
  />
);

export default GroupsSection;
