import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import { Wrapper, Title, IconWrapper } from './styled';

interface ColumnItemsSectionProps {
  reverse?: boolean;
  title: string;
  description: string;
  icon: React.JSXElementConstructor<React.SVGProps<SVGElement>>;
}

const FeatureItemSection: React.FC<ColumnItemsSectionProps> = ({
  title,
  description,
  icon: Icon,
  reverse = false,
}) => (
  <SectionWrapper>
    <Wrapper data-reverse={reverse || undefined}>
      <Title>{title}</Title>

      <IconWrapper data-reverse={reverse || undefined}>
        <Icon />
      </IconWrapper>

      <p>{description}</p>
    </Wrapper>
  </SectionWrapper>
);

export default FeatureItemSection;
