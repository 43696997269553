import * as React from 'react';

import { Mouse } from 'theme/icons/other';

import { Wrapper } from './styled';

const MouseSection = () => (
  <Wrapper>
    <Mouse />
  </Wrapper>
);

export default MouseSection;
