import * as React from 'react';

import { Eagle, Navigation, PriceTree, Translate } from 'theme/icons/200';

import ColumnItemsSection from 'components/ColumnItemsSection';

const Requirements = () => (
  <ColumnItemsSection
    title='Отвечаем современным требованиям'
    items={[
      {
        title: 'Отечественная разработка, не требующая сторонних лицензий',
        icon: Eagle,
      },
      {
        title: 'Высокая горизонтальная и вертикальная масштабируемость',
        icon: Navigation,
      },
      {
        title: 'Низкая совокупная стоимость владения (TCO)',
        icon: PriceTree,
      },
      {
        title: 'Поддержка многоязыкового интерфейса',
        icon: Translate,
      },
    ]}
  />
);

export default Requirements;
