import * as React from 'react';

import { Key, Shield, Workspaces, UserCard } from 'theme/icons/200';

import ColumnItemsSection from 'components/ColumnItemsSection';

const Safety = () => (
  <ColumnItemsSection
    title='Надежность и безопасность'
    items={[
      {
        title: 'Использует шифрование при передаче данных',
        icon: Key,
      },
      {
        title:
          'Встраивается в контур безопасности предприятия, интегрируется с DLP/SIEM системами',
        icon: Shield,
      },
      {
        title: 'Рабочие пространства пользователей изолированы',
        icon: Workspaces,
      },
      {
        title: 'Гибкое управление правами пользователей',
        icon: UserCard,
      },
    ]}
  />
);

export default Safety;
