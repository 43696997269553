import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  @media (min-width: ${MEDIA.TABLET}) {
    display: flex;
    padding: 60px 0;
  }
`;
