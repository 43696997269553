import { styled } from '@linaria/react';

import { COLOR, TEXT, MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;

  text-align: center;

  @media (min-width: ${MEDIA.TABLET}) {
    padding: 80px 30px;
  }

  @media (min-width: ${MEDIA.TABLET}) {
    padding: 50px 130px;
  }
`;

export const Description = styled.p`
  margin-top: 40px;

  ${TEXT.SMALL_REGULAR};
  opacity: 0.4;

  a {
    color: var(${COLOR.C_PRIMARY});
  }
`;
